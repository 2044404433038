<template>
  <div class="deviceBug frame">
    <vue-seamless-scroll :data="listData" class="seamless-warp" :class-option="classOption">
      <ul class="list">
        <li v-for="(item, index) in listData" class="item" :key="index">
          <span class="list_name">
            <div ref="isOverflow">{{ item.deviceName }}</div>
          </span>
          <span class="alarmName" v-text="item.name">
            <div ref="isOverflow">{{ item.name }}</div>
          </span>
          <span class="list_time">
            <div ref="isOverflow">{{ item.exceptionTime }}</div>
          </span>
        </li>
      </ul>
    </vue-seamless-scroll>
  </div>
</template>

<script>
import * as vueSeamlessScroll from 'vue-seamless-scroll';
export default {
  name: 'ExceptionMsg',
  components: {
    vueSeamlessScroll
  },
  data() {
    return {
      listData: [],
      classOption: {
        step: 0.3
      }
    };
  },
  created() {
    this.init();
    setInterval(() => {
      this.init();
    }, 10 * 1000);
  },
  methods: {
    async init() {
      const res = await this.$apis.LSD.AlarmDevice();
      this.listData = res;
      this.$nextTick(() => {
        let isOverflow = this.$refs.isOverflow;
        if (!isOverflow) {
          return;
        }
        isOverflow.forEach(item => {
          item.className = '';
          let cWidth = item.clientWidth;
          let sWidth = item.scrollWidth;
          if (sWidth > cWidth) {
            item.className = 'nameRoll';
          }
        });
      });
    }
  }
};
</script>
<style scoped>
.list > li {
  border: 0;
  margin-bottom: 8px;
  padding: 3px 0px;
}
.list {
  /* background-color: rgba(16, 56, 98, 0.6) !important; */

  border-radius: 2px;
}
.exception .list-status {
  width: 46px !important;
  height: 16px !important;
  line-height: 14px !important;
  border-radius: 10px;
  margin-top: 5px;
  text-align: center;
  margin-right: 20px;
}
.item {
  background-color: #103964;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 10px !important;
  padding-left: 5px !important;
  justify-content: space-around;
  color: #f3f3f3;
}
.exception .list-status.list-status-white {
  border: 1px solid #fffbf6;
}
.exception .list-status.list-status-orange {
  color: rgba(233, 66, 1, 1);
  border: 1px solid rgba(233, 66, 1, 1);
}
.exception .list-status.list-status-yellow {
  color: rgba(240, 142, 3, 1);
  border: 1px solid rgba(240, 142, 3, 1);
}

.deviceBug {
  padding-right: 20px;
}
.seamless-warp {
  width: 100%;
  margin: 20px 0px;
  overflow: hidden;
}
.list_name,
.alarmName {
  width: 32%;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
}
.alarmName {
  margin: 0px 10px;
}
.list_time {
  width: 36%;
  overflow: hidden;
  white-space: nowrap;
}
.nameRoll {
  width: 120px;
  animation: move 7s linear;
  animation-iteration-count: infinite;
}
@keyframes move {
  0% {
    transform: translateX(0px);
  }
  10% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-130px);
  }
}
</style>
