<template>
  <div class="left">
    <DeviceCloud />
    <DeviceConnect />
    <weekDeviceStatus />
    <weekPerfrom />
  </div>
</template>

<script>
import DeviceCloud from '../components/left/DeviceCloud.vue';
import DeviceConnect from '../components/left/DeviceConnect.vue';
import weekDeviceStatus from '../components/left/weekDeviceStatus.vue';
import weekPerfrom from '../components/left/weekPerfrom.vue';
export default {
  components: { DeviceCloud, DeviceConnect, weekPerfrom, weekDeviceStatus },
  data() {
    return {};
  }
};
</script>

<style></style>
