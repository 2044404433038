<template>
  <div class="right">
    <Rankbox />
    <deviceBug />
    <deviceAlarm />
  </div>
</template>

<script>
import Rankbox from '../components/right/Rankbox.vue';
import deviceBug from '../components/right/deviceBug.vue';
import deviceAlarm from '../components/right/deviceAlarm.vue';
export default {
  data() {
    return {};
  },
  components: { Rankbox, deviceBug, deviceAlarm }
};
</script>
