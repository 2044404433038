<template>
  <div class="center">
    <DeviceRunningDetail />
    <deviceOee :ChartsData="ChartsData" />
  </div>
</template>

<script>
import DeviceRunningDetail from '../components/center/DeviceRunningDetail.vue';
import deviceOee from '../components/center/deviceOee.vue';
export default {
  data() {
    return {
      ChartsData: null
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const chartsRes = await this.$apis.LSD.deviceOee({ xAxisDateFormat: 'dd' });
      let oee = [];
      let performance = [];
      let availability = [];
      let quality = [];
      if (chartsRes.yaxisValues) {
        chartsRes.yaxisValues.forEach(item => {
          oee.push(item.oee * 100);
          performance.push(item.performance * 100);
          availability.push(item.availability * 100);
          quality.push(item.quality * 100);
        });
      }
      this.ChartsData = {
        oee,
        performance,
        availability,
        quality,
        xaxisValues: chartsRes.xaxisValues
      };
    }
  },
  components: { DeviceRunningDetail, deviceOee }
};
</script>
