<template>
  <div class="backgroud">
    <div class="bg_grid"></div>
    <div class="alphabet">
      <div class="alphabet_son" :style="`color:${item.color};animationDelay:${Math.random() * 5 + 's'}`" v-for="(item, index) in domlist" :key="index">{{ item.name }}</div>
    </div>
    <!-- <canvas id="canvas"></canvas> -->
    <!-- 底部扩散圆 -->
    <div class="circle">
      <div id="one" class="one"></div>
      <div class="two"></div>
      <div class="three"></div>
      <div class="four"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bgc',
  data() {
    return {
      domlist: []
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let text = 'hsyDyzAbCdEfGhIjKlMnOpQrStUvWxYz';
      let colorlist = ['#0d6e81', '#0c3e63'];
      for (let i = 0; i < 60; i++) {
        let textContent = '';
        let num = Math.floor(Math.random() * 4);
        for (let index = 0; index < num; index++) {
          textContent = textContent.concat(text);
        }
        this.domlist.push({ name: textContent, color: colorlist[i % 2] });
      }
    }
  }
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
body,
.backgroud {
  width: 100vw;
  height: 100vh;
}
body {
  background-color: #001630;
}
.backgroud {
  position: relative;
  overflow: hidden;
  perspective: 500px;
  background: url('../assets/img/header/backgroud_light.png') no-repeat;
  background-position-x: 50%;
}
.bg_grid {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: url('../assets/img/main/center_bg_grid.png') no-repeat;
  background-position: 50% 50%;
  opacity: 0.7;
}
.alphabet {
  position: absolute;
  left: 28%;
  top: 0;
  width: 1000px;
  height: 1000px;
  overflow: hidden;
  transform: translateX(-120px) rotate(90deg);
}
.alphabet_son {
  display: block;
  margin: 0px 10px;
  margin: 0px 10px;
  opacity: 0;
  animation: roll 5s linear;
  animation-iteration-count: infinite;
}
@keyframes roll {
  0% {
    opacity: 1;
    display: block;
    transform: translateX(-700px);
  }
  100% {
    transform: translateX(0px);
    opacity: 0;
  }
}
.circle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 600px;
  height: 600px;
  transform: translate(-48%, -54%) rotateX(254deg);
}
.one {
  position: absolute;
  top: 1%;
  left: 6%;
  transform: translate(-50%, -50%);
  width: 480px;
  height: 480px;
  border: 4px solid #0299e1;
  border-radius: 50%;
  box-shadow: #00b4fa 0px 0px 1000px 1000px inset;
  z-index: 1;
  opacity: 1;
  animation: warn 5s linear;
  animation-iteration-count: infinite;
}
.two {
  position: absolute;
  top: 1%;
  left: 6%;
  transform: translate(-50%, -50%);
  width: 480px;
  height: 480px;
  border: 4px solid #0299e1;
  box-shadow: #00b4fa 0px 0px 1000px 1000px inset;
  border-radius: 50%;
  z-index: 1;
  opacity: 0;
  animation: warn 4s linear;
  animation-iteration-count: infinite;
}
.three {
  position: absolute;
  top: 1%;
  left: 6%;
  transform: translate(-50%, -50%);
  width: 480px;
  height: 480px;
  border: 4px solid #0299e1;
  box-shadow: #00b4fa 0px 0px 1000px 1000px inset;
  border-radius: 50%;
  z-index: 1;
  opacity: 0;
  animation: warn 3s linear;
  animation-iteration-count: infinite;
}
.four {
  position: absolute;
  top: 1%;
  left: 6%;
  transform: translate(-50%, -50%);
  width: 480px;
  height: 480px;
  border: 4px solid #0299e1;
  box-shadow: #00b4fa 0px 0px 1000px 1000px inset;
  border-radius: 50%;
  z-index: 1;
  opacity: 0;
  animation: warn 4.5s linear;
  animation-iteration-count: infinite;
}
@keyframes warn {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    transform: scale(0.6);
    opacity: 0.5;
  }

  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
</style>
