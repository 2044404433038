<template>
  <div class="DeviceConnect frame">
    <div class="statusIcon" v-if="listData">
      <div :class="listData[statusIndex].deviceStatus === 1 ? 'onicon' : 'officon'"></div>
      <div class="textIcon">
        <div style="color: #fefefe">{{ listData[statusIndex].deviceStatus === 1 ? '在线' : '断开' }}</div>
        <div class="smallIcon"></div>
      </div>
    </div>
    <div class="statusTable">
      <!-- <vue-seamless-scroll :data="listData" class="seamless-warp" :class-option="classOption"> -->
      <ul class="list" :style="`transform: translateY(-${statusIndex * 20}px)`">
        <li :class="index === statusIndex ? 'active' : ''" v-for="(item, index) in listData" :key="index">
          <span class="list_name">
            <div ref="isOverflow">{{ item.name }}</div>
          </span>
          <span class="list-time" v-text="item.deviceStatusUpdateTime"></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import vueSeamlessScroll from 'vue-seamless-scroll';
export default {
  data() {
    return {
      listData: [],
      statusIndex: 0,
      timer: null
    };
  },
  components: {
    // vueSeamlessScroll
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      clearInterval(this.timer);
      this.statusIndex = 0;
      const res = await this.$apis.LSD.realDevice();
      if (!res) {
        return false;
      }
      this.listData = res;
      if (this.listData.length === 1) {
        return false;
      }
      this.timer = setInterval(() => {
        this.statusIndex++;
        if (this.statusIndex >= this.listData.length) {
          this.init();
          this.statusIndex = 0;
        }
      }, 5000);
      this.$nextTick(() => {
        let isOverflow = this.$refs.isOverflow;
        isOverflow.forEach(item => {
          item.className = '';
          let cWidth = item.clientWidth;
          let sWidth = item.scrollWidth;
          if (sWidth > cWidth) {
            item.className = 'nameRoll';
          }
        });
      });
    }
  }
};
</script>
<style scoped>
.DeviceConnect {
  justify-content: center;
}
.statusIcon,
.statusTable {
  box-sizing: border-box;
  padding: 10px 5px;
  padding-right: 15px;
  overflow: hidden;
}
.statusIcon {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.statusTable {
  width: 300px;
  height: 81%;
  margin-top: 22px;
}
.onicon {
  width: 42px;
  height: 42px;
  background: url('../../../assets/img/defaultimg/device_on.png') no-repeat;
  background-size: cover;
  margin-bottom: 10px;
}
.officon {
  width: 42px;
  height: 42px;
  background: url('../../../assets/img/defaultimg/device_off.png') no-repeat;
  background-size: cover;
  margin-bottom: 10px;
}
.textIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  border: 1px solid rgba(39, 250, 255, 0.2);
}
.smallIcon {
  width: 18px;
  height: 18px;
  margin-left: 5px;
  background: url('../../../assets/img/defaultimg/text_icon.png') no-repeat;
  background-size: cover;
}
.list > li {
  position: relative;
  top: -5px;
  display: flex;
  justify-content: space-between;
  background: rgba(67, 186, 254, 0.1);
  color: rgba(87, 187, 221, 1);
  margin-bottom: 10px;
  padding: 0 2px;
}
.active {
  color: #f3f3f3 !important;
  background-color: rgba(67, 186, 254, 0.3) !important;
}
.list_name,
.list-time {
  width: 50%;
  overflow: hidden;
  white-space: nowrap;
}
.list_name > .nameRoll {
  width: 120px;
  animation: move 7s linear;
  animation-iteration-count: infinite;
}
@keyframes move {
  0% {
    transform: translateX(0px);
  }
  10% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-130px);
  }
}
</style>
