<template>
  <div class="rankBox">
    <bugRank />
    <perfromRank />
  </div>
</template>
<script>
import bugRank from './RankCharts/bugRank.vue';
import perfromRank from './RankCharts/perfromRank.vue';
export default {
  data() {
    return {};
  },
  components: { bugRank, perfromRank }
};
</script>
