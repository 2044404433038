<template>
  <div class="datamoudle">
    <!-- 头部 -->
    <div class="header">
      <span>{{ userData.name }}</span>
    </div>
    <!-- 中心产线模块 -->
    <div class="main">
      <Left />
      <Center />
      <Right />
    </div>
  </div>
</template>

<script>
// style="font-family: MyFontName"
import Left from './layout/left.vue';
import Center from './layout/center.vue';
import Right from './layout/right.vue';
export default {
  name: 'dataMoudle',
  data() {
    return {
      userData: {
        name: ''
      }
    };
  },
  components: {
    Center,
    Left,
    Right
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const userRes = await this.$apis.FactoryChart.getCurrentTenantInfo();
      if (userRes) {
        this.userData = userRes;
      }
    }
  }
};
</script>
<style scoped></style>
