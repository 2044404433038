<template>
  <div class="Sce" ref="LSD">
    <Bgc />
    <Datamoudle />
  </div>
</template>

<script>
import Bgc from './pages/background.vue';
import Datamoudle from './pages/DataMoudle.vue';

export default {
  name: 'LSD',
  components: {
    Bgc,
    Datamoudle
  },
  mounted() {
    window.addEventListener('keyup', event => {
      if (event.code === 'F11') {
        this.$router.go(0);
      }
    });
  }
};
</script>
<style>
@import url('./assets/css/index.css');
</style>
