<template>
  <div class="bugRank frame">
    <div class="showNav">
      {{ Nav_Array[Nav_index].label }}
    </div>
    <div class="deviceItem" v-for="(item, index) in Ranklist" :key="index">
      <div class="NameOrValue">
        <div class="name">
          <div ref="isOverflow">{{ item.deviceName }}</div>
        </div>
        <div class="value">{{ Nav_Array[Nav_index].value === 'oee' ? item[Nav_Array[Nav_index].value] + '%' : item[Nav_Array[Nav_index].value] }}</div>
      </div>
      <div class="progress">
        <el-progress stroke-linecap="square" :percentage="Nav_Array[Nav_index].value === 'oee' ? item.oee - 0 : item.rate - 0" :show-text="false"></el-progress>
      </div>
    </div>
  </div>
</template>

<script>
import { msFormatter } from '@/axios/utils.js';
export default {
  data() {
    return {
      timer: null,
      Nav_index: 0,
      Nav_Array: [
        {
          label: '离线时长(min)',
          code: 'DEVICE_OFFLINE_DESC',
          value: 'offlineTime'
        },
        {
          label: '设备效率',
          code: 'DEVICE_OEE_DESC',
          value: 'oee'
        },
        {
          label: '运行时长(min)',
          code: 'DEVICE_RUNNING_DESC',
          value: 'runningTime'
        },
        {
          label: '待机时长(min)',
          code: 'DEVICE_FREE_DESC',
          value: 'freeTime'
        },
        {
          label: '故障时长(min)',
          code: 'DEVICE_ALARM_DESC',
          value: 'alarmTime'
        }
      ],
      Ranklist: []
    };
  },
  mounted() {
    this.Nav_index = 0;
    this.init();
    this.timer = setInterval(() => {
      this.init();
    }, 120 * 1000);
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    async init() {
      this.Ranklist = [];
      this.Nav_index++;
      if (this.Nav_index === 5) {
        this.Nav_index = 0;
      }
      const res = await this.$apis.LSD.weekPerfromRank({ reportStatisticsType: this.Nav_Array[this.Nav_index].code, topN: 7 });
      res.yaxisValues.forEach((item, index) => {
        let rate = 0;
        if (index === 0) {
          rate = 100;
        } else {
          rate = (item[this.Nav_Array[this.Nav_index].value] / res.yaxisValues[0][this.Nav_Array[this.Nav_index].value]) * 100;
        }
        if (item[this.Nav_Array[this.Nav_index].value] === 0) {
          rate = 0;
        }
        this.Ranklist.push({
          oee: item.oee - 0,
          runningTime: msFormatter(item.runningTime),
          freeTime: msFormatter(item.freeTime),
          alarmTime: msFormatter(item.alarmTime),
          offlineTime: msFormatter(item.offlineTime),
          deviceName: item.xAxisLabel,
          rate
        });
      });

      this.$nextTick(() => {
        let isOverflow = this.$refs.isOverflow;
        isOverflow.forEach(item => {
          item.className = '';
          let cWidth = item.clientWidth;
          let sWidth = item.scrollWidth;
          if (sWidth > cWidth) {
            item.className = 'nameRoll';
          }
        });
      });
    }
  }
};
</script>

<style scoped lang="scss">
.showNav {
  width: 126px;
  height: 26px;
  margin: 20px 0px 10px 10px;
  line-height: 24px;
  text-align: center;
  border: 1px solid rgba(39, 250, 255, 0.3);
  color: #f39800;
  opacity: 1;
}
.bugRank {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.deviceItem {
  flex: 1;
  width: 100%;
  max-height: 60px;
  display: flex;
  flex-direction: column;
}
.NameOrValue {
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
}
.name {
  width: 120px;
  color: #fefefe;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
}
.name > .nameRoll {
  width: 120px;
  animation: move 10s linear;
  animation-iteration-count: infinite;
}
@keyframes move {
  0% {
    transform: translateX(0px);
  }
  10% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-130px);
  }
}
.value {
  font-family: MyFontName;
  color: #28ffff;
}
.progress {
  flex: 1;
}
/deep/.el-progress-bar__inner {
  background-image: repeating-linear-gradient(120deg, transparent 0px, transparent 2px, #032647 2px, #032647 5px, transparent 5px);
}
/deep/ .el-progress-bar__outer {
  background-color: rgba(67, 186, 254, 0.26);
  background-image: repeating-linear-gradient(120deg, transparent 0px, transparent 2px, #032647 2px, #032647 5px, transparent 5px);
}
</style>
