<template>
  <div class="deivceStatus frame">
    <div class="stateBar"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  name: 'stateBar',
  data() {
    return {
      // 空闲
      free: [],
      // 异常
      alarm: [],
      // 关机
      off: [],
      // 加工
      running: [],
      // 日期
      xAxis: []
    };
  },
  async mounted() {
    this.xAxis = [];
    this.running = [];
    this.off = [];
    this.alarm = [];
    this.free = [];
    const statusRes = await this.$apis.LSD.statusTimer({ xAxisDateFormat: 'dd' });
    this.xAxis = statusRes.xaxisValues;
    if (statusRes.yaxisValues) {
      statusRes.yaxisValues.forEach(item => {
        this.free.push((item.freeTime / 1000 / 60 / 60).toFixed(2));
        this.alarm.push((item.alarmTime / 1000 / 60 / 60).toFixed(2));
        this.off.push((item.offlineTime / 1000 / 60 / 60).toFixed(2));
        this.running.push((item.runningTime / 1000 / 60 / 60).toFixed(2));
      });
    }
    this.init();
  },
  methods: {
    init() {
      let chartDom = document.querySelector('.stateBar');
      let myChart = echarts.init(chartDom);
      myChart.clear();
      let option;
      option = {
        color: ['#909399', '#EA3837', '#F39800', '#5EBB38'],
        legend: {
          data: ['运行', '空闲', '故障', '离线'],
          right: 3,
          top: 4,
          textStyle: {
            // 图例字体大小
            fontSize: 10,
            color: 'white',
            height: 8,
            rich: {
              a: {
                verticalAlign: 'top'
              }
            }
          },
          // 图例大小
          itemWidth: 13,
          itemHeight: 3,
          align: 'auto'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter(params) {
            let str = `${params[0].name}日<br/>`;
            params.forEach(item => {
              str = str.concat(`${item.marker + item.seriesName}:${item.value}小时<br/>`);
            });
            return str;
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: '20%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.xAxis,
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#FEFEFE'
            },
            fontSize: 8
          },
          axisLine: {
            show: false
          }
        },
        yAxis: {
          max: 24,
          splitNumber: 5,
          type: 'value',
          axisTick: {
            show: true,
            lineStyle: {
              color: '#959595'
            }
          },
          splitLine: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#FEFEFE'
            },
            fontSize: 8
          }
        },
        series: [
          {
            name: '离线',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: this.off,
            barWidth: 10,
            itemStyle: {
              normal: {
                color() {
                  return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba(144, 147, 153, 1)' },
                    { offset: 1, color: 'rgba(29, 28, 26, 0)' }
                  ]);
                }
              }
            }
          },

          {
            name: '故障',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: this.alarm,
            showBackground: true,
            barWidth: 10,
            itemStyle: {
              normal: {
                color() {
                  return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba(234, 56, 55, 1)' },
                    { offset: 1, color: 'rgba(29, 28, 26, 0)' }
                  ]);
                }
              }
            }
          },

          {
            name: '空闲',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: this.free,
            barWidth: 10,
            itemStyle: {
              normal: {
                color() {
                  return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba(243, 152, 0, 1)' },
                    { offset: 1, color: 'rgba(29, 28, 26, 0)' }
                  ]);
                }
              }
            }
          },

          {
            name: '运行',
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            barWidth: 10,
            data: this.running,
            itemStyle: {
              normal: {
                color() {
                  return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba(94, 187, 56, 1)' },
                    { offset: 1, color: 'rgba(29, 28, 26, 0)' }
                  ]);
                }
              }
            }
          }
        ]
      };
      myChart.clear();
      option && myChart.setOption(option);
    }
  }
};
</script>

<style>
.stateBar,
.stateBarparent {
  position: relative;
  width: 100%;
  height: 100%;
}
.towfour {
  position: absolute;
  width: 17px;
  height: 20px;
  top: 15%;
  left: 3%;
  font-size: 10px;
  background-color: #000000;
  color: #6e7079;
}
</style>
