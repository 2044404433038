<template>
  <div class="deviceAlarm frame"></div>
</template>
<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {};
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const res = await this.$apis.LSD.Alarmcharts({ xAxisDateFormat: 'dd' });
      let xaxisValues = [];
      let num = [];
      let time = [];
      if (res.yaxisValues) {
        res.yaxisValues.forEach(item => {
          num.push(item.totalNumber);
          time.push(item.totalTime / 1000 / 60);
        });
      }
      xaxisValues = res.xaxisValues;
      let chartDom = document.querySelector('.deviceAlarm');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        tooltip: {
          trigger: 'axis',
          formatter(params) {
            let min = `${(params[1].value % 60).toFixed(0)}`;
            let h = `${Math.floor(params[1].value / 60)}`;
            return `${params[0].name}号<br/>${`${params[0].marker + params[0].seriesName}:${params[0].value}`}次<br/>${`${params[1].marker}时长:`} ${h}小时${min}分钟 `;
          }
        },
        grid: {
          top: '25%',
          right: '12%',
          left: '12%',
          bottom: '20%'
        },
        xAxis: [
          {
            type: 'category',
            axisTick: {
              show: false
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: '#FEFEFE'
              },
              fontSize: 6
            },
            splitLine: {
              show: false
            },
            axisLine: {
              show: false
            },
            data: xaxisValues
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '时长(min)',
            min: 0,
            position: 'left',
            axisLabel: {
              show: true,
              textStyle: {
                color: '#FEFEFE'
              },
              fontSize: 6
            },
            nameTextStyle: {
              color: '#FEFEFE'
            },
            offset: 0,
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            }
          },
          {
            type: 'value',
            name: '次数',
            min: 0,
            position: 'right',
            axisLabel: {
              show: true,
              textStyle: {
                color: '#FEFEFE'
              },
              fontSize: 6
            },
            nameTextStyle: {
              color: '#FEFEFE'
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            }
          }
        ],
        series: [
          {
            barWidth: 8,
            name: '次数',
            type: 'bar',
            data: num,
            yAxisIndex: 1,
            barGap: '80%',
            itemStyle: {
              normal: {
                color() {
                  return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba(243, 152, 0, 1)' },
                    { offset: 1, color: 'rgba(29, 28, 26, 0)' }
                  ]);
                }
              }
            }
          },
          {
            barWidth: 8,
            name: '时长(min)',
            type: 'bar',
            data: time,
            itemStyle: {
              normal: {
                color() {
                  return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba(234, 56, 55, 1)' },
                    { offset: 1, color: 'rgba(29, 28, 26, 0)' }
                  ]);
                }
              }
            }
          }
        ]
      };

      option && myChart.setOption(option);
    }
  }
};
</script>
